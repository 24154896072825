export enum ATTACHMENT_TYPE {
  LABORATORY = 'LABORATORY',
  CONTRACT = 'CONTRACT',
  PROJECT_DATA = 'PROJECT_DATA',
  PROJECT_OFFER = 'PROJECT_OFFER',
  PROJECT_CONTRACT = 'PROJECT_CONTRACT',
  PROJECT_CONTRACT_SIGNED = 'PROJECT_CONTRACT_SIGNED',
  PROJECT_PVP = 'PROJECT_PVP',
  PROJECT_REPORT = 'PROJECT_REPORT',
  PROJECT_INVOICE = 'PROJECT_INVOICE',
  PROJECT_COMMAND = 'PROJECT_ORDER',
  PROJECT_WORKSHEET = 'PROJECT_WORKSHEET',
  USER_SIGNATURE = 'USER_SIGNATURE',
  WORKSTATION_AUTHORIZATION = 'WORKSTATION_AUTHORIZATION',
  USER_STAMP = 'USER_STAMP'
}
export class UploadedFile extends File {
  id?: string;

  uri?: string;

  originalName?: string;

  attachmentType: ATTACHMENT_TYPE;

  created?: string;
}
